import React, { useState } from "react";
import Botpoison from "@botpoison/browser";

const FORMSPARK_ACTION_URL = "https://submit-form.com/spaQ97v3";
const botpoison = new Botpoison({
    publicKey: "pk_f6214672-d62f-49a9-828c-99a8437311d5"
});

export default function ContactForm() {

    const [inputText, setInputText] = useState('');
    const [textareaMessage, setTextareaMessage] = useState('');
    const [select, setSelect] = useState('Solicitud');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { solution } = await botpoison.challenge();
        fetch(FORMSPARK_ACTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                '_email': {
                    from: 'John Doe',
                    subject: 'Nuevo mensaje desde formulario web',
                    replyto: 'mandarina@outlook.com',
                    template: {
                        title: false,
                        footer: false
                    }
                },
                'nombre': inputText,
                'motivo': select,
                'mensaje': textareaMessage,
                _botpoison: solution,
            }),
        })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.error(error);
        });
    };

    return (
        <div className="contact-form">
            <h2 className="mb-4 font-bold text-xl uppercase tracking-wide">CONTACTO</h2>
            <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-x-2">
                <div className="mb-3 col-start-1 col-end-3">
                    <input className="w-full border rounded py-1.5 px-2" name="name" type="text" placeholder="Nombre" value={inputText} onChange={e => setInputText(e.target.value)} required />
                </div>
                <div className="mb-3 col-start-3 col-end-5">
                    <select className="w-full border rounded py-1.5 px-2" name="selector" value={select} onChange={e => setSelect(e.target.value)} >
                        <option value="Solictud">Solicitud</option>
                        <option value="Reclamo">Reclamo</option>
                    </select>
                </div>
                <div className="mb-1 col-start-1 col-end-5">
                    <textarea className="border rounded w-full py-1.5 px-2 h-28" name="message" placeholder="Mensaje" value={textareaMessage} onChange={e => setTextareaMessage(e.target.value)} required></textarea>
                </div>
                <div>
                    <button type="submit" class="bg-green-500 hover:bg-green-700 text-white rounded-md px-3 py-2 font-bold mb-3">Enviar</button>
                </div>
            </form>
        </div>
    )
}