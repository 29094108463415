import React from "react";
import { Helmet } from "react-helmet";
// import Botpoison from "@botpoison/browser";
import { ContactMap } from "../components/ContactMap";
import ContactForm from "../components/ContactForm";
// import styles from "../styles/Contact.module.css";

export default function Contact() {

    return (
        <div>
            <Helmet>
                <title>Contacto — MOMENT COFFEE</title>
            </Helmet>

            {/* <div className="bg-gray-50">
                <div className="h-80 flex justify-center items-center">
                    <h1 className="text-center font-bold text-md uppercase w-2/6">
                        Este es un texto de introducción de Moment Coffee, el mejor lugar del mundo mundial
                    </h1>
                </div>
            </div> */}

            <ContactMap />

            <div className="contact-place w-full py-12">
                <div className="mx-auto w-4/5 flex flex-wrap">
                    <div className="w-1/2 mt-1">
                        <div className="mb-6 ">
                            <h2 className="font-bold uppercase ">Moment Estado</h2>
                            <p className="leading-tight">
                                Estado 233<br />
                                Curicó
                            </p>
                        </div>
                        <div className="mb-6 ">
                            <h2 className="font-bold uppercase ">Moment Av España</h2>
                            <p className="leading-tight">
                                Av España 6208<br />
                                Curicó
                            </p>
                        </div>
                        <div className="">
                            <h2 className="font-bold uppercase ">Moment René León</h2>
                            <p className="leading-tight">
                                René León 910<br />
                                Curicó
                            </p>
                        </div>
                    </div>
                    <div className="w-1/2">

                        <ContactForm />

                    </div>
                </div>
            </div>

        </div>
    )
}