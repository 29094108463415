import React from "react";
import { Link } from "react-router-dom";
// import styles from '../styles/Header.module.css'

export default function Header() {
    return (
        <header className="flex justify-between w-full py-6 px-6 mb-0">
            <div className="font-bold tracking-wide text-lg font-black">
                <Link to="/">MOMENT</Link>
            </div>
            <div className="">
                <Link to="/" className="mx-7 uppercase font-semibold tracking-wide text-sm text-black hover:text-gray-600 transition-all">Inicio</Link>
                <Link to="/" className="mx-7 uppercase font-semibold tracking-wide text-sm text-yellow-600 hover:text-gray-600 transition-all">Pide Online</Link>
                <Link to="/" className="mx-7 uppercase font-semibold tracking-wide text-sm text-black hover:text-gray-600 transition-all">Tienda</Link>
                <Link to="/about" className="mx-7 uppercase font-semibold tracking-wide text-sm text-black hover:text-gray-600 transition-all">Conoce MOMENT</Link>
                <Link to="/contact" className="ml-7 uppercase font-semibold tracking-wide text-sm text-black hover:text-gray-600 transition-all">Contacto</Link>
            </div>
        </header>
    )
}