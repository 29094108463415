// import calc from "postcss-calc";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const heroStyles = {
    backgroundImage: 'url(hero-image.webp)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '50vh'
}

const chooseStepStyles = {
    minHeight: '64vh'
}

export default function Home() {
    return (
        <div>
            <Helmet>
                <title>MOMENT COFFEE</title>
            </Helmet>
            <div className="home relative">
                
                <div className="hero flex w-full" style={heroStyles}></div>

                <div className="choose-step flex" style={chooseStepStyles}>
                    <div className="w-1/2 flex justify-center items-center bg-gray-50">
                        <div className="text-center w-3/6">
                            <h1 className="font-bold uppercase text-2xl mb-5">
                                PIDE CAFÉ ONLINE
                            </h1>
                            <p className="leading-tight mb-7 text-gray-800">
                                Tu tiempo es valioso. <br />
                                Pide café <span className="font-bold">para llevar</span> o <span className="font-bold">para servir</span> antes de llegar a nuestros locales.
                            </p>
                            <div>
                                <Link className="inline-block py-2 px-4 bg-transparent hover:bg-black border border-black text-black hover:text-white font-bold tracking-wide uppercase text-xs transition-all" to="/">Pedir aquí</Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2 flex justify-center items-center">
                        <div className="text-center w-3/6">
                            <h1 className="font-bold uppercase text-2xl">
                                VISITA NUESTRA TIENDA
                            </h1>
                            <p className="mb-5"><span className="text-yellow-700 font-semibold">15% DSCTO en toda la tienda</span></p>
                            <p className="leading-tight mb-7 text-gray-800">
                                <span className="font-bold">Cafés</span> y <span className="font-bold">productos</span> para quienes saben elegir un buen momento.
                            </p>
                            <div>
                                <Link className="inline-block py-2 px-4 bg-transparent hover:bg-black border border-black text-black hover:text-white font-bold tracking-wide uppercase text-xs transition-all" to="/">Comprar aquí</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}