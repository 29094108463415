import React from "react";
import { Helmet } from "react-helmet";

export default function About() {
    return (
        <div>
            <Helmet>
                <title>Conoce MOMENT — MOMENT COFFEE</title>
            </Helmet>
            <div className="px-6">
                <h2 className="mb-3">MOMENT COFFEE</h2>
            </div>
        </div>
    )
}