import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapContainerStyles = {
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  height: '360px',
};

const mapStyles = {
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
};

export class ContactMap extends Component {
  render() {
    return (
      <div style={mapContainerStyles}>
        <Map
          google={this.props.google}
          zoom={16}
          style={mapStyles}
          initialCenter={
            {
              lat: -34.9847042,
              lng: -71.2295042
            }
          }
        >
          <Marker
            title={'Moment Av España'}
            name={'Moment Av España'}
            position={{lat: -34.985275, lng: -71.2268329}} 
          />
          <Marker
            title={'Moment René León'}
            name={'Moment René León'}
            position={{lat: -34.983729, lng: -71.2302771}} 
          />
          <Marker
            title={'Moment Estado'}
            name={'Moment Estado'}
            position={{lat: -34.9857377, lng: -71.2367437}} 
          />
        </Map>
      </div>
    );
  }
}

ContactMap = GoogleApiWrapper({
  apiKey: 'AIzaSyAwVYPKcGUBxiFsoaaMnnCa-QuWCTUwA-8'
})(ContactMap);